// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

.padding10{
    display: flex;
    margin: 10px;
}

.wrap{
    display: flex;
    justify-content: center;
}

.countdown-wrapper {
    max-width: 800px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 40px 0;
 }
.countdown-box {
    background-color: #010102;
    font-size: 50px;
    font-weight: 700;
    color: #dcd6f7;
    border-radius: 15px;
    width: 160px;
    height: 160px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.legend {
    font-size: 30px;
    color: #a6b1e1;
}
.heading {
    font-size: 45px;
}
.highlight {
    color: #7f78d2;
}